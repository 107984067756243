
<template>
    <div class="row gy-2">
      <div class="col-12">
        <div class="row mb-3 gy-2 justify-content-between">
          <div class="col-sm-12 d-flex justify-content-between">
            <div class="d-flex">
              <div class="dropdown no-arrow me-2">
                <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bx bx-menu"></i>
                </a>
                <ul class="dropdown-menu">
                  <li @click.prevent="toggleFilters()">
                    <button class="dropdown-item" type="button">
                      Toggle Filters
                    </button>
                  </li>
                </ul>
              </div>
              <div class="input-group">
                <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="input-group mb-0">
              <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
              <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
            </div>
          </div>
        </div>
        <div class="overflow-hidden">
          <common-portlet headertitle="Filters"
            :visible="enableFilters" :open="true">
            <div class="loading-viewport">
              <div class="card-body p-lg-3">
                <history-filter-form 
                @startSearch="setFilters($event)"
                @resetFilters="initializeAll()" />
              </div>
            </div>
          </common-portlet>
        </div>
        <div class="mb-3">
          <p class="mb-0">Showing {{paginationInfo}} results</p>
        </div>
        <is-loading v-if="isLoading" />
        <div v-else>
          <div class="row">
            <div class="col-12" v-for="(item, index) in pageData.data" :key="index">
              <history-card :item="item">
              </history-card>
            </div>
          </div>
          <div class="mb-4">
            <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
            :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  
  import _ from 'lodash';
  import IsLoading from '@/components/IsLoading.vue'
  import CommonPortlet from '@/components/portlets/CommonPortlet.vue';
  import HistoryCard from '@/components/cards/HistoryCard.vue';
  import HistoryFilterForm from '@/components/forms/HistoryFilterForm.vue';
    
  export default {
    name: "case-histories",
    components:{
      IsLoading,
      HistoryCard,
      CommonPortlet,
      HistoryFilterForm
    },
    props:{
      caseId:{
        type: Number,
        required: true
      }
    },
    data() {
      return {
        isLoading: true,
        searchQuery:"",
        enableFilters: false,
        queryFilters: null,
        pageOptions: [10, 50, 100, 500, 1000],
        pageData:{
          data: []
        }
      }
    },
    computed: {
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      currentPage: {
        get() {
          return this.pageData.current_page
        },
        set(val) {
          if(!this.pageData.current_page || this.pageData.current_page == val) return
          this.fetchItems(val)
        }
      },
      itemsPerPage: {
        get() {
          return +this.pageData.per_page || 10
        },
        set(val) {
          this.fetchItems(1, val)
        }
      },
      paginationInfo(){
        if(!this.pageData.total) return '0 - 0 of 0';
        return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
        - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
        ? this.currentPage * this.itemsPerPage : this.pageData.total}
        of ${this.pageData.total}`
      },
    },
    methods: {
      updateSearchQuery: _.debounce(function(string) {
        this.fetchItems()
      }, 2000),
      fetchItems(page, per_page = null){
        if(!this.caseId) return; 
        let payload = { page: page || 1, per_page: per_page || this.itemsPerPage,
                      case_id: this.caseId }
        if(this.searchQuery){ payload.search = this.searchQuery }
        if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
        this.isLoading = true
        this.$http.get(`/histories?${new URLSearchParams(payload).toString()}`)
          .then((response) => {
            this.isLoading = false
            if(response.data.success){
              this.pageData = response.data.data
            }
          })
      },
      exportRecord() {
        const payload = {
          url: '/generate-record',
          data: {  case_id: this.caseId, }
        }
        this.$store.dispatch("changeLoaderValue", true)
        this.serverReceipt(payload)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.status != 200){
              this.alertError('Could not generate medical histories');
            }
        })
      },
      toggleFilters(){
        this.enableFilters = !this.enableFilters; 
        this.initializeAll()
      },
      setFilters(data){
        this.queryFilters = data
        this.fetchItems()
      },
      initializeAll(){
        this.queryFilters = null
      }
    },
    created() {
      this.fetchItems()
    }
  }
  
  </script>
  
    