
<template>
  <div class="">
    <div class="row gy-2">
      <div v-for="(item, itIndex) in items" :key="itIndex" class="col-md-12">
        <div v-if="item" class="card border-top overflow-hidden">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div v-if="item.lawyer" class="d-flex align-items-center">
                <div  class="cursor-pointer me-3">
                  <img class="avatar rounded-circle" :src="absoluteUrl(item.lawyer.image)" />
                </div>
                <div class="cursor-pointer">
                  <div class="text-dark fw-bold font-sm text-capitalize">
                    <router-link :to="`/users/${item.lawyer.id}`">
                      {{ item.lawyer.first_name}} {{ item.lawyer.last_name}}
                    </router-link>
                  </div>
                  <div><span class="text-dark font-sm">{{ item.lawyer.mobile }}</span></div>
                </div>
              </div>
              <div class="dropdown dropstart no-arrow">
                <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <button @click.prevent="createQuery(item)" class="dropdown-item"
                      type="button"> Send Query </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-3">
              <div class="" >Status:  <span v-html="statusBadge(item.status)"></span></div>
              <div class="mt-2" v-if="item.duty">Assigned Duty: {{ item.duty.title }} </div>
              <div class="mt-2">Assigned At: {{$filters.date(item.assigned_at) }}</div>
              <div class="mt-2" v-if="item.deactivated_at">Deactivated At: {{$filters.date(item.deactivated_at) }} </div>
              <p v-if="item.creator" class="small mt-2 text-muted mb-0">
                Assigned by: {{item.creator.first_name}} {{item.creator.last_name}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="popupModalSendQuery" hide-footer centered
      title="Send a Law Officer query" body-class="loading-viewport"
      title-class="fs-6" @hide="initializeAll()">
      <query-form 
        v-if="popupModalSendQuery"
        @closeMe="initializeAll()"
        :pivot-item="pivotItem"
      />
    </b-modal>
  </div>
</template>

<script>
import QueryForm from '@/components/forms/QueryForm.vue';
  
export default {
  components: { QueryForm },
  name: "case-lawyers",
  props:{
    items:{
      type: Array,
      default:() => ([])
    }
  },
  data(){
    return {
      popupModalSendQuery: false,
      pivotItem: null,
    }
  },
  methods:{
    createQuery(item){
      this.pivotItem = {
        case_id: item.case_id,
        recipient_id: item.lawyer_id
      };
      this.popupModalSendQuery = true;
    },
    initializeAll(){
      this.pivotItem = null;
      this.popupModalSendQuery = false;
    },
  }
}

</script>
  
  